@include susy-breakpoint($pad, $pad-susy){
	#content-wp #item ul#list > li{
		margin-right: 3px;
	}
	#content-wp #item {
		background: url(#{$img-path}/service-item-bg.jpg);
	}
	#content-wp dl#content dd h2{
		font-size: 16px;
		line-height: 25px;
	}
	#content-wp dl#content dd{
		@include span(33.3%);
		margin: 0;
		h2{ 
			padding:0 10px;
		}
	}
}