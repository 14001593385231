@import "grids";
@import "reset";
@import "global";
#banner {
	background: url(#{$img-path}/bg/about.jpg) no-repeat center 0;
	background-size: 100%;
}

#content-wp {
	.inner {
		background: url(#{$img-path}/inter-content-bg.png) no-repeat center 0;
	}
	
	.inner>h1 {
		padding: 80px 0;
		width: 1000px;
		margin: 0 auto;
		text-align: center;
		font: 700 16px/36px $font-yahei;
		color: $inter-text;
		text-shadow: $text-white-shadow;
	}
	
	#team {
		>h2 {
			color: $global-text-color;
			font: 700 italic 40px $font-yahei;
			text-shadow: $text-white-shadow;
			text-align: center;
			margin-bottom: 60px;
			background: url(#{$img-path}/inter-about-h1-bg.png) no-repeat center 7px;
		}
		
		// 團隊分類
		ul#sort {
			@include with-layout($desktop) {
				@include container;
			}
			
			margin:0 auto;
			padding-bottom: 60px;
			@import "team-sort";
			
			li {
				width: 16.6%;
				float: left;
				text-align: center;
				font: 22px $title-font;
				color: #999;
				height: 36px;
				
				span.filter.active {
					font-size: 0;
					
					&:before {
						content: attr(data-hover);
						font: italic 18px/36px $font-yahei;
						text-shadow: $text-white-shadow;
					}
				}
				
				&:hover {
					font-size: 0;
					
					span.filter {
						&:hover {
							&:before {
								content: attr(data-hover);
								font: italic 18px/36px $font-yahei;
								color: #b39246;
								text-shadow: $text-white-shadow;
							}
						}
					}
				}
			}
		}
		#team-info {
			overflow: hidden;
		}
		
		ul#list {
			li {
				width: 20%;
				background: #000;
				display: inline-block;
				margin-left: -4px;
				vertical-align: top;
				overflow: hidden;
				
				>img {
					width: 100%;
					height: auto;
					display: block;
					transition: all 2s linear;
					transform-origin: 50% 50%;
					
					&:hover {
						transform: scale(1.1, 1.1);
						opacity: .9;
					}
				}
				
				&.portfolio {
					box-sizing: border-box;
					display: none;
					cursor: pointer;
				}
			}
		}
	}
}
// 個人介紹的html
#personal-intro {
	padding: 100px span(0.5) 0 span(0.5);
	color: #383838;
	.teacher {
		text-align: center;
		.person {
			display: block;
			margin: 0 auto;
			max-width: 100%;
		}
	}
	
	.left {
		width: 40%;
		float: left;
		
		>img {
			border: 10px solid #fff;
		}
	}
	
	#title {
		border-bottom: 1px solid #b6b6b6;
		box-shadow: $text-white-shadow;
	}
	
	h1 {
		font: 800 50px $font-yahei;
		text-shadow: $text-white-shadow;
		padding-bottom: 35px;
		@include inline-block;
	}
	
	b {
		font: 700 15px/24px $font-yahei;
		@include inline-block;
		text-align: left;
		margin-left: 12px;
		text-shadow: $text-white-shadow;
		
		p {
			font-size: 28px;
		}
	}
	
	#content {
		width: 100%;
		padding: 60px 0;
		.person {
			display: block;
			margin: 0 auto;
			width: span(4 of 14);
		}
		ul {
			font: 14px $font-yahei;
			lost-utility: clearfix;
			
			li {
				margin-bottom: 7px;
				lost-column: 1/3 3 30px;
				border-left: 2px solid #989292;
				padding-left: 8px;
			}
		}
	}
}
#inline-template{
	display: none
}


@import "pad/about";
@import "mobile/about";