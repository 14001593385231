@include susy-breakpoint($mobile, $mobile-susy){
	#content-wp{
		padding: 50px 0 0 0;
		.inner{
		}
		ul#menu{
			text-align: center;
			width: 100%;
			margin: 0 auto;
			margin-bottom: 50px;
			li{
				@include inline-block;
				font: 16px $font-yahei;
				width: 40%;
				margin: 0;
				cursor: pointer;
			}
		}
		#photowall{
			width: 100%;
			.list{
				text-align: center;
				li{
					margin:0;
					width: 50%;
					float:left;
					height: auto;
					margin-bottom:0;
					position:relative;
					margin-bottom: 1px;
					&.portfolio{
						display: none;
					}
					a{
						width: 100%;
						// height: auto;
						pointer-events: none;
						cursor: default;
						border-radius: 0;
						display: block;
						> img{
							width: 100%;
							height: auto;
							border-radius:0;
							position: relative;
							left: 0;
							margin: 0;
							float:left;
						}
						&::before{
							display:flex;
							border-radius: 0;
							content:'';
							position:absolute;
							width:100%;
							height:100%;
							-moz-box-shadow: none;
							-webkit-box-shadow: none;
							box-shadow: none;
						}
					}
				}
			}
		}
	}
	footer{
		border-top: 15px solid #816545;
		padding-top: 45px;
	}
}