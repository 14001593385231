@import "grids";
@import "reset";
@import "global";
#banner{
	background: url(#{$img-path}/bg/about.jpg) no-repeat center 0;
	background-size: 100%;
}
#content-wp{
	// 三個服務項目
	dl#content{
		@include container($desktop);
		padding-top: 120px;
		margin: 0 auto;
		dd{
			@include span(3);
			text-align: center;
			margin: 0 40px;
			vertical-align: top;
			&:last-child {
				margin-right: 0;
			}
			&.last-child {
				margin-right: 0;
			}
			h1{
				font:  italic 42px $font-yahei;
				color: $global-text-color;
				text-align: center;
				text-shadow: $text-white-shadow;
				margin-bottom: 30px;
			}
			h2{
				font:15px/24px $font-yahei;
				padding: 0;
				color:#686868;
			}
			span{
				@include icon-bg;
				width: 270px;
				height: 270px;
				margin-bottom: 30px;
				&.one{
					background-position: -480px -10px;
				}
				&.two{
					background-position: -480px -280px;
				}
				&.three{
					background-position: -480px -550px;
				}
			}
		}
	}
	// 配套項目
	#item{
		width: 100%;
		position: relative;
		margin-top: 88px;
		z-index: 3;
		background: url(#{$img-path}/service-item-bg.jpg) repeat;
		
		&:after{
			content: "";
			left: 0;
			top: 0;
			right: 0;
			bottom: 0;
			position: absolute;
			background: url(#{$img-path}/service-item-repeat-bg.png) repeat;
		}
		.inner{
			background: none;
			padding: 60px 0 80px 0;
			height: 100%;
			position: relative;
			z-index: 5;
			> h3{
				font: 700 italic 36px $font-yahei;
				text-align: center;
				color: rgba(255,255,255,.7);
				margin-bottom: 30px;
				i{
					@include icon-bg;
					width: 90px;
					height: 60px;
					background-position: -10px -200px;
					vertical-align: bottom;
					margin-right: 10px;
				}
				b{
					@include icon-bg;
					width: 90px;
					height: 60px;
					background-position: -355px -200px;
					vertical-align: bottom;
					margin-left: 20px;
				}
			}
			h2{
				font: italic 18px/32px $font-yahei;
				text-align: center;
				color: rgba(255,255,255,.7);
			}
		}
		ul#list{
			margin-top: 70px;
			text-align: center;
			> li{
				height: 500px;
				background: url(#{$img-path}/service-list-li-bg.png) repeat;
				font-style: italic;
				text-align: center;
				vertical-align: top;
				width: span(25%);
				float: left;
				&.last-child{
					margin-right: 0;
				}
				> img{
					width: 92%;
					padding-top: 14px;
					margin-bottom: 35px;
				}
				a{
					color: #D2919D;
					-webkit-transition: all 0.1s ease-in-out;
					-moz-transition: all 0.1s ease-in-out;
					-ms-transition: all 0.1s ease-in-out;
					-o-transition: all 0.1s ease-in-out;
					transition: all 0.1s ease-in-out;
					&:hover{
						color: #fff;
					}
				}
			}
			h4{
				color: #fff;
				font: 700 italic 20px $font-yahei;
				margin-bottom: 20px;
			}
			h2{
				font:italic 23px $font-yahei;
				color: #cab083;
				margin-bottom: 5px;
			}
			h3{
				color: #dbcdb4;
				font: 14px $font-yahei;
				margin-bottom: 20px;
				letter-spacing: -.5px;
			}
			ol {
				li{
					font: 15px $font-yahei;
					color: #fff;
					margin-bottom: 10px;
				}
			}
			@include susy-breakpoint($pad, $pad-susy){
				> li{
					width: span(50%);
					float: left;
					margin: 0!important;
				}
			}
		}
	}
}

@import "pad/service";
@import "mobile/service";