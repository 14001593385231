@include susy-breakpoint($mobile, $mobile-susy){
	#content-wp{
		// 三個服務項目
		dl#content{
			width: 100%;
			padding-top: 60px;
			dd{
				@include inline-block;
				width: 100%;
				text-align: center;
				margin: 0;
				margin-bottom: 30px;
				h1{
					margin-bottom: 10px;
				}
				span{
					&.one{
						background-position: -480px -10px;
					}
					&.two{
						background-position: -480px -280px;
					}
					&.three{
						background-position: -480px -550px;
					}
				}
			}
		}
		// 配套項目
		#item{
			width: 100%;
			position: relative;
			margin-top: 88px;
			z-index: 3;
			background: #000 url(#{$img-path}/bg/service-item-bg.jpg) no-repeat 0 0;
			background-size: cover;
			&:after{
				content: "";
				left: 0;
				top: 0;
				right: 0;
				bottom: 0;
				position: absolute;
				background: url(#{$img-path}/service-item-repeat-bg.png) repeat;
			}
			.inner{
				background: none;
				padding: 60px 0 0 0;
				height: 100%;
				position: relative;
				z-index: 5;
				> h3{
					font: 700 italic 36px $font-yahei;
					text-align: center;
					color: rgba(255,255,255,.7);
					margin-bottom: 30px;
					i{
						display: none;
					}
					b{
						display: none;
					}
				}
				h2{
					font: italic 16px/26px $font-yahei;
					text-align: center;
					padding: 0 30px;
					color: rgba(255,255,255,.7);
				}
			}
			ul#list{
				margin-top: 40px;
				text-align: center;
				> li{
					width: 100%;
					background: url(#{$img-path}/service-list-li-bg.png) repeat;
					font-style: italic;
					text-align: center;
					margin: 0;
					display: block;
					height: auto;
				}
			}
		}
	}
}