.GITheWall ul li.GI_TW_Selected_Row {
    transition: margin-bottom 0.3s ease-in;
}

.GI_TW_expander {
    position: absolute;
    z-index: 1;
    top: 0;
    background: url(#{$img-path}/team-bg.png) repeat 0 0;
    left: 0;
    width: 100%;
    // min-width:1200px;
    height: 0;
    overflow: hidden;
    box-shadow: inset 0 -2px 10px rgba(0,0,0,.2);
}
.GI_TW_expander.animating {
    overflow: hidden !important;
}
.GI_TW_expander.opened {
    overflow: visible;
    height: auto!important;
    margin-bottom: 10px;
}

.GI_TW_expander .GI_TW_pointer {
    position: absolute;
    margin-left: -20px;
    margin-top: -20px;
    width: 0;
    height: 0;
    border-left: 20px solid transparent;
    border-right: 20px solid transparent;
    border-bottom: 20px solid $global-text-color;
    transition: left 0.3s ease-in;
}
.GI_TW_expander .GI_TW_expander-inner {
    width: 100%;
    margin: 0 auto;
    overflow: hidden;
    height: auto!important;
}
.GI_TW_expander .GI_TW_expander-inner .GI_TW_fullimg {
    position: relative;
    width: 100%;
    height: 580px;
    margin: 10px 0 0;
    text-align: center;
    overflow: hidden;
}
.GI_TW_expander .GI_TW_expander-inner .GI_TW_fullimg img {
    position: relative;
}
.GI_TW_expander .GI_TW_Controls {
    z-index: 1;
}
.GI_TW_expander .GI_TW_close {
    background: url(#{$img-path}/close.png) no-repeat;
    position: absolute;
    width: 40px;
    height: 40px;
    top: 20px;
    right: 20px;
    cursor: pointer;
    z-index: 100;
}
.GI_TW_expander .GI_TW_arrow {
    position: absolute;
    top: 50%;
    cursor: pointer;
}
.GI_TW_expander .GI_TW_arrow.GI_TW_hidden {
    display: none;
    
}
.GI_TW_expander .GI_TW_arrow.GI_TW_next {
    right: 60px;
    .fa-arrow-right{
        height: 66px;
        width: 34px;
        display: block;
        background:url(#{$img-path}/right.png) no-repeat;
    }
}
.GI_TW_expander .GI_TW_arrow.GI_TW_prev {
    left: 60px;
    .fa-arrow-left{
        height: 66px;
        width: 34px;
        display: block;
        background:url(#{$img-path}/left.png) no-repeat;
    }
}
.GI_TW_expander .GI_TW_loading {
    width: 20px;
    height: 20px;
    border-radius: 50%;
    background: #ddd;
    box-shadow: 0 0 1px #cccccc, 15px 30px 1px #cccccc, -15px 30px 1px #cccccc;
    position: absolute;
    top: 50%;
    left: 50%;
    margin: -25px 0 0;
    animation: loader 0.5s infinite ease-in-out both;
}

@-moz-keyframes loader {
    0% {
        background: #ddd;
    }
    
    33% {
        background: #ccc;
        box-shadow: 0 0 1px #cccccc, 15px 30px 1px #cccccc, -15px 30px 1px #dddddd;
    }
    
    66% {
        background: #ccc;
        box-shadow: 0 0 1px #cccccc, 15px 30px 1px #dddddd, -15px 30px 1px #cccccc;
    }
}

@-webkit-keyframes loader {
    0% {
        background: #ddd;
    }
    
    33% {
        background: #ccc;
        box-shadow: 0 0 1px #cccccc, 15px 30px 1px #cccccc, -15px 30px 1px #dddddd;
    }
    
    66% {
        background: #ccc;
        box-shadow: 0 0 1px #cccccc, 15px 30px 1px #dddddd, -15px 30px 1px #cccccc;
    }
}

@-o-keyframes loader {
    0% {
        background: #ddd;
    }
    
    33% {
        background: #ccc;
        box-shadow: 0 0 1px #cccccc, 15px 30px 1px #cccccc, -15px 30px 1px #dddddd;
    }
    
    66% {
        background: #ccc;
        box-shadow: 0 0 1px #cccccc, 15px 30px 1px #dddddd, -15px 30px 1px #cccccc;
    }
}

@-ms-keyframes loader {
    0% {
        background: #ddd;
    }
    
    33% {
        background: #ccc;
        box-shadow: 0 0 1px #cccccc, 15px 30px 1px #cccccc, -15px 30px 1px #dddddd;
    }
    
    66% {
        background: #ccc;
        box-shadow: 0 0 1px #cccccc, 15px 30px 1px #dddddd, -15px 30px 1px #cccccc;
    }
}

@keyframes loader {
    0% {
        background: #ddd;
    }
    
    33% {
        background: #ccc;
        box-shadow: 0 0 1px #cccccc, 15px 30px 1px #cccccc, -15px 30px 1px #dddddd;
    }
    
    66% {
        background: #ccc;
        box-shadow: 0 0 1px #cccccc, 15px 30px 1px #dddddd, -15px 30px 1px #cccccc;
    }
}

