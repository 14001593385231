@include susy-breakpoint($mobile, $mobile-susy){
	#trigger-overlay {
		width: 40px;
		height: 40px;
		position: absolute;
		z-index: 0;
		right: 20px;
		top: 20px;
		overflow: hidden;
		border: none;
		background: url(#{$img-path}/open.svg) no-repeat center center;
		text-indent: -9999px;
		background-size: 100% 100%;
		outline: none;

	}
	/* Overlay style */
	.overlay {
		position: fixed;
		width: 100%;
		height: 100%;
		top: 0;
		left: 0;
		background: rgba(181,152,90,0.9);
		z-index: 2;
	}

	/* Overlay closing cross */
	.overlay .overlay-close {
		width: 80px;
		height: 80px;
		position: absolute;
		right: 20px;
		top: 20px;
		overflow: hidden;
		border: none;
		background: url(#{$img-path}/cross.png) no-repeat center center;
		text-indent: 200%;
		color: transparent;
		outline: none;
		z-index: 100;
	}

	/* Menu style */
	.overlay #nav {
		text-align: center;
		position: relative;
		top: 50%;
		height: 60%;
		font-size: 54px;
		-webkit-transform: translateY(-60%);
		transform: translateY(-60%);
	}

	.overlay ul {
		padding: 0;
		margin: 0 auto;
		display: inline-block;
		height: 100%;
		position: relative;
	}

	.overlay ul li {
		display: block;
		height: 20%;
		height: calc(100% / 6);
		min-height: 54px;
	}

	.overlay ul li a {
		display: block;
		color: #fff;
		width: 100%;
		-webkit-transition: all 0.2s;
		transition: all 0.2s;
		height: 100%;
	}


	/* Effects */
	.overlay-slidedown {
		visibility: hidden;
		-webkit-transform: translateY(-100%);
		transform: translateY(-100%);
		-webkit-transition: -webkit-transform 0.4s ease-in-out, visibility 0s 0.4s;
		transition: transform 0.4s ease-in-out, visibility 0s 0.4s;
	}

	.overlay-slidedown.open {
		visibility: visible;
		-webkit-transform: translateY(0%);
		transform: translateY(0%);
		-webkit-transition: -webkit-transform 0.4s ease-in-out;
		transition: transform 0.4s ease-in-out;
	}
}
@media screen and (max-height: 30.5em) {
	.overlay nav {
		height: 70%;
		font-size: 34px;
	}
	.overlay ul li {
		min-height: 34px;
	}
}