#banner{
	height: 645px;
	box-shadow: 0 2px 2px rgba(215,215,215,1);
	background: #fff;
	background: url(#{$img-path}/bg/contact.jpg) no-repeat center 0;
	&:after{
		background: url(#{$img-path}/contact-pointer.png) no-repeat;
		position: absolute;
		top: 0;
		right: 0;
		width: 100%;
		height: 645px;
	}
	.top-bg{
		background: url(#{$img-path}/contact-top-bg.png) no-repeat;
		width: 422px;
		height: 224px;
		position: absolute;
		top: 0;
		left: 50%;
		margin-left: -240px;
		z-index: 10;
	}
	.inner{
		width: 900px;
		height: 100%;
		margin: 0 auto;
		position: relative;
		#head-info{
			bottom: 240px;
			top: inherit;
			left: 40px;
			margin: 0;
			.top{
				background-position: -10px -500px;
			}
			.bottom{
				background-position: -10px -540px;
			}
			h3{
				color: #ae8f4c;
				text-shadow: 0 1px 5px rgba(0,0,0,.3);
			}
			h2{
				color: #B9B9B9;
				font-style: italic;
				text-shadow: none;
			}
		}
	}
}
#content-wp{
	position: relative;
	z-index: 100;
	min-height: 450px;
	background:none;
	z-index: 100;
	#form{
		top: -180px;
		left: 50%;
		margin-left: -485px;
		width: 970px;
		height: 580px;
		background: url(#{$img-path}/contact-form-bg.png) no-repeat;
		position: absolute;
		dl{
			padding: 16px 30px 0 30px;
			dd{
				float:left;
			}
			#left{
				width: 370px;
				position: relative;
				height: 537px;
				.line{
					width: 100px;
					display: block;
					margin: 30px auto 0 auto;
					@include susy-breakpoint($pad,$pad-susy){
						width: 100px;
						position: absolute;
						right: 20px;
						top: 0;
					}
					@include susy-breakpoint($mobile,$mobile-susy){
						width: 30%;
						margin: 20px auto;
					}
				}
				.info{
					width: 230px;
					height: 165px;
					position: absolute;
					left: 50%;
					top: 140px;
					margin-left: -115px;
					z-index: 10;
					.icon{
						@include icon-bg;
						width: 18px;
						height: 18px;
						background-position: -180px -500px;
						margin-left: 100px;
						margin-bottom: 10px;
					}
					h1{
						text-align: center;
						font: 38px $title-font;
						color: #b5985a;
						margin-bottom: 5px;
						b{
							font: italic 20px $font-yahei;

						}
					}
					h2{
						font:600 37px $lato;
						color: #BEBEBE;
						letter-spacing: -2px;
						-webkit-font-smoothing: antialiased;
						margin-bottom: 15px;
					}
					h3{
						font: 15px $font-yahei;
						color: #BEBEBE;
						text-align: center;
						color: #b5985a;
					}
				}
			}
			#right{
				width: 535px;
				padding-left: 45px;
				padding-top: 40px;
				input[type="text"]{
					width: 415px;
					height: 40px;
					padding-left: 10px;
					border:none;
					border-radius: 3px;
					background: #efefef;
					box-shadow: inset 0 2px 0 rgba(191,192,192,.1);
					margin-bottom: 20px;
					font-family: $font-yahei;
					color: #b5985a;
					&.code{
						width: 300px;
					}
				}
				input[type="submit"]{
					width: 425px;
					height: 55px;
					border:none;
					border-radius: 3px;
					background: #b5985a;
					box-shadow: inset 0 2px 0 rgba(191,192,192,.1);
					margin-bottom: 20px;
					font: 30px $font-yahei;
					color: #fff;
					cursor: pointer
				}
				textarea{
					width: 415px;
					color: #b5985a;
					padding: 10px 0 0 10px;
					height: 150px;
					border-radius: 3px;
					border:none;
					font-family: $font-yahei;
					background: #efefef;
					box-shadow: inset 0 2px 0 rgba(191,192,192,.1);
					margin-bottom: 20px;
				}
			}
		}
	}
}
