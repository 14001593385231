@import "mobile/top-menu"; 

@include susy-breakpoint(max-width 700px, $mobile-susy){
	
	#ind-service {
		#item{
			width: 100%;
			a{
				width: 100%;
				-webkit-transition:none;
				margin: 0;
				&:hover{
					-webkit-transform:translateY(0);
				}
			}
			h2,h1{
				p{
					font-size: 18px;
				}
			}
		}
	}
	#outer-wp{
		#content-wp{
			dl#intro{
				dd{
					&#about{
						padding-top: 620px;
					}
				}
			}
		}
	}
	footer{
		padding-top: 20px;
	}
}
@include susy-breakpoint($mobile, $mobile-susy){
	#video, .parallax-mirror, .parallax-slider{
		display: none;
	}
	#video1{
		background-position: center -190px;
		height: 430px;
	}
	#ind-service {
		bottom: -38%;
	}
	#outer-wp{
		height: 100%;
		.w1200{
			width: 100%;
		}
		header{
			#trigger-overlay, .overlay-close{
				display: block;
			}
			.logo{
				display: none;
			}
			a.logo-text{
				position: relative;
				top: 20px;
			}
			&.fix-nav{
				overflow: visible;
				height: 90px;
				&.fix-nav-shrink{
					height: 90px;
					box-shadow: none;
					a.logo-text{
						top: 20px;
						opacity: 1;
					}
					ul#nav{
						height:60%;
						padding: 0;
					}
				}
			}
			ul#nav{
				background:none;
				height: 90px;
				padding:0;
				height: 60%;
				a:hover{
					h2{
						color: #000;
					}
				}
				li{
					margin: 0;
					width: 100%;
					&.mid-space{
						margin-right: 0!important;
					}
					h2{
						font-size: 35px;
						color: #fff;
						line-height:54px;
						p{
							display: none;
						}
					}
				}
			}
			
			#trigger-overlay{
				top: 15px;
			}
		}
		> #head-info{
			margin-top: -10%;
			width: 320px;
			h3{
				font-size: responsive 30px 42px;
			}
			h2{
				padding: 0 70px;
			}
		}
		#inter{
			#head-info{
				.top,.bottom{
					display: none;
				}
			}
		}
		#content-wp{
			dl#intro{
				padding-top: 30px;
				dd#memory{
					display: none;
				}
				dd#about{
					background:none;
					padding-bottom: 0;
					padding-top: 670px;
					h3{
						font-size: 42px;
						background:none;
						margin-bottom: 25px;
					}
					h2{
						display: none;
					}
					p{
						padding: 0 30px;
						line-height: 25px;
						margin-bottom: 0;
						font-size: 16px;
					}
				}
			}
		}
		footer{
			padding: 40px 0 0 0;
			ul.menu{
				display: none;
			}
			#cami_system_include{
				display: none;
			}
			
			h2{
				border-bottom: none;
				margin: 0;
				em{
					display: inline-block;
					margin: 0 15px 10px 0;
					&:nth-child(2n) {
						margin-right: 0;
					}
				}
				
			}
		}
		#inter{
			#banner{
				margin-top: 90px;
				height: 215px;
			}
			&.contact{
				background: url(#{$img-path}/contact-wp-bg.jpg) repeat;
				#banner{
					background: url(#{$img-path}/bg/mobile-contact.jpg) no-repeat center 0!important;
					background-size: cover;
				}
			}
		}
		#contact-footer{
			padding-top: 0px;
			background: url(#{$img-path}/contact-wp-bg.jpg) repeat;
		}
		.online{
			// display: none!important;
		}
	}
}

@media screen and (max-width: 468px) {
	#video1{
		max-width: 100%;
		background-position: center -50px;
		height: 450px;
		background-attachment: unset;
	}
	#outer-wp{
		#content-wp{
			dl#intro{
				dd#about{
					padding-top: 620px;
				}
			}
		}
	}
}
@media screen and (max-width: 320px) {
	#outer-wp header {
		#trigger-overlay {
			right: 10px;
			top: 15px;
		}
		.overlay .overlay-close {
			right: 5px;
			top: 5px;
		}
	}
}