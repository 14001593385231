/** 
 * Everslider - Responsive jQuery Carousel Plugin
 */

 .everslider {
 	width: 88%;
 	margin: 0 auto;
 	overflow: hidden;
 }

 .everslider .es-slides {
 	position: relative;
 	width: 100000px;
 	margin: 0;
 	padding: 0;
 	list-style: none;
 	-webkit-transform: translate3d(0,0,0);
 }

 .everslider .es-slides > li {
 	position: relative;
 	float: left;
 	padding: 0!important;
 	border: 0!important;
 	min-width: 326px;
 	min-height: 287px;
 	-webkit-transform: translate3d(0,0,0);
 	margin-left: 0px;
 }


 /* Preload */

 .everslider {
 	background: url(#{$img-path}/preload.gif) no-repeat center;
 }

 .everslider.es-slides-ready {
 	background: none;
 }

 .es-slides {
 	visibility: hidden;
 }

 .es-slides-ready .es-slides {
 	visibility: visible;
 }


 /* Navigation */

 .es-navigation a {
 	position: absolute;
 	top: 50%;
 	margin-top: -17px;

 }

 .es-navigation .es-prev { left: 40px; }
 .es-navigation .es-next { right: 40px; }

 .es-navigation .es-first,
 .es-navigation .es-last {
 	opacity: 0.5;
 	filter: alpha(opacity=50);

 }

 .es-navigation a span {
 	display: block;
 	width: 35px;
 	height: 64px;
 	text-indent: -9999px;
 }

 .es-navigation .es-prev span {
 	background: url(#{$img-path}/arrows.png) no-repeat 0 0;
 }

 .es-navigation .es-next span {
 	background: url(#{$img-path}/arrows.png) no-repeat -20px 0;
 }

 .es-navigation .es-prev span.alt-arrow {
 	background: url(#{$img-path}/arrows.png) no-repeat 0 0;
 }

 .es-navigation .es-next span.alt-arrow {
 	background: url(#{$img-path}/arrows.png) no-repeat -35px 0;
 }

 /* Pagination */

 .es-pagination {
 	position: absolute;
 	left: 50%;
 	bottom: 10px;
 }


 /* Ticker */

 .es-ticker {
 	position: absolute;
 	bottom: 0;
 	right: 0;
 	display: none;
 }

 .es-ticker a {
 	display: block;
 	padding: 7px;
 	background: #eee;
 }

 .es-ticker a span {
 	display: block;
 	width: 20px;
 	height: 20px;
 	text-indent: -9999px;
 }

 .es-ticker .es-play span {
 	background: url(#{$img-path}/ticker.png) no-repeat 0 0;
 }

 .es-ticker .es-pause span {
 	background: url(#{$img-path}/ticker.png) no-repeat -20px 0;
 }


 /* fullwidth slider */

 .fullwidth-slider .es-slides > li {
 	/* 1.6 ~ image width/height */
 	width: 326px;	
 	height: 287px;
 	background: #111;
 	background:url(#{$img-path}/slider-bg.png) no-repeat 0 0;

 }

 .fullwidth-slider .es-navigation a {
 	top: 50%;
 	margin-top: -30px;
 }

 .fullwidth-slider .es-ticker {
 	bottom: auto;
 	top: 0;
 	margin-right: 34px;
 }

 .fullwidth-slider .es-slides > li img  {
 	opacity: 1;
 	padding-top: 66px;
 	padding-left: 63px;
 	-webkit-transition: opacity .3s;
 	-moz-transition: opacity .3s;
 	-o-transition: opacity .3s;
 	transition: opacity .3s;
 }

 .fullwidth-slider .es-slides > li:hover img {
 	opacity: 0.8;
 }



 /* Retina */
