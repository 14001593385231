@include susy-breakpoint($mobile, $mobile-susy){
	#banner{
		width: 100%;
		height: 300px;
		box-shadow: none;
		background: #fff;
		overflow: hidden;
		&:after{
			background: url(#{$img-path}/ind-slider-pointer.png) repeat;
			position: absolute;
			top: 0;
			right: 0;
			width: 100%;
			height: 380px;
			z-index: 100;
		}
		.top-bg{
			display: none;
		}
	}
	#content-wp{
		position: relative;
		z-index: 100;
		min-height: 100%;
		background:none;
		z-index: 100;
		#form{
			top: -50px;
			width: 90%;
			min-height: 600px;
			background: url(#{$img-path}/form-mobile-bg.jpg) repeat 0 0;
			position: relative;
			left: 0;
			margin:0 auto;
			height: auto;
			padding-bottom: 10px;
			dl{
				padding:0;
				width: 90%;
				margin: 0 auto;
				dd{
					display: block;
					float:none;	
				}
				#left{
					width: 100%;
					position: relative;
					height: 330px;
					@include susy-breakpoint($mobile-400,$mobile-susy){
						height: 280px;
					}
					.info{
						width: 100%;
						height: 165px;
						z-index: 10;
						margin: 0 auto;
						position: relative;
						left: 0;
						top: 40px;
						h2{
							text-align: center;
						}
						.icon{
							display: block;
							margin: 0 auto;
						}
					}
				}
				#right{
					width: 100%;
					padding: 0;
					input[type="text"]{
						width: 100%;
						&.code{
							width: 150px;
						}
					}
					input[type="submit"]{
						width: 100%;
					}
					textarea{
						width: 100%;
					}
				}
			}
		}
	}
}
