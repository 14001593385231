$font-yahei:Arial, Helvetica, \5FAE\8EDF\6B63\9ED1\9AD4, sans-serif;
$font-ming:Arial, Helvetica,\7EC6\660E\4F53, sans-serif;
$global-text-color:#b5985a;
$inter-text:#AFA99C;
$text-white-shadow:0 1px 0 rgba(255,255,255,1);
$eng-font:CenturySchool;
$title-font:bodoniMT;
$sort-title-font:bodoniMTConds;
$text-shadow-white: 1px 1px 0 rgba(255,255,255,1);
$lato: lato;
$img-path:"/assets/images"; //



@font-face {
    font-family:'CenturySchool';
    src:url("../font/CenturySchoolbook-Italic.eot");
    src:url("../font/CenturySchoolbook-Italic.eot") format("embedded-opentype"),
    url("../font/CenturySchoolbook-Italic.ttf") format("truetype"),
}
@font-face {
    font-family:'bodoniMT';
    src:url("../font/BodoniMT-Italic.eot");
    src:url("../font/BodoniMT-Italic.eot") format("embedded-opentype"),
    url("../font/BodoniMT-Italic.ttf") format("truetype"),
    url("../font/BodoniMT-Italic.svg#BodoniMT-Italic") format("svg");
}
@font-face {
    font-family:'bodoniMTConds';
    src:url("../font/BodoniMTCondensed.eot");
    src:url("../font/BodoniMTCondensed.eot") format("embedded-opentype"),
    url("../font/BodoniMTCondensed.ttf") format("truetype"),
    url("../font/BodoniMTCondensed.svg#BodoniMTCondensed") format("svg");
}

@font-face {
    font-family:'lato';
    src:url("../font/Lato-Thin.eot");
    src:url("../font/Lato-Thin.eot") format("embedded-opentype"),
    url("../font/Lato-Thin.ttf") format("truetype"),
    url("../font/Lato-Thin.svg#Lato-Thin") format("svg");
}


@mixin transition{
	-webkit-transition: all 0.2s ease-in-out;
	transition: all 0.2s ease-in-out;
}
@mixin icon-bg{
	background:url(#{$img-path}/all-icon.png) no-repeat;
	display: inline-block;
	*display: inline;
	zoom: 1;
}
@mixin inline-block {
	display: inline-block;
	*display: inline;
	zoom: 1;
}
@mixin w1200{
	width:1200px;
}
