@include susy-breakpoint($pad, $pad-susy){
	#video1{
		background-position: center -120px;
		height: 500px;
	}
	#ind-service{
		bottom: -34%;
		#item{
			a{
				lost-column: 1/3 3 0;
				margin: 0;
			}
			h2,h1{
				p{
					font-size: responsive 12px 18px;
				}
			}
		}
	}
	#outer-wp{
		height: 100%;
		header{
			&.fix-nav{
				overflow: visible;
				height: 130px;
				&.fix-nav-shrink{
					height: 90px;
					box-shadow: none;
					.logo{
						display: none;
					}
					a.logo-text{
						top: 20px;
						opacity: 1;
					}
					ul#nav{
						height:60%;
						padding: 0;
					}
				}
			}
			ul#nav{
				background:none;
				height: 90px;
				padding:0;
				height: 60%;
				a:hover{
					h2{
						color: #000;
					}
				}
				li{
					margin: 0;
					width: 100%;
					&.mid-space{
						margin-right: 0!important;
					}
					h2{
						font-size: 35px;
						color: #fff;
						line-height:54px;
						p{
							display: none;
						}
					}
				}
			}
		}
		&.bg{
			footer{
				background: url(#{$img-path}/contact-wp-bg.jpg) repeat;
			}
		}
		
		// 首頁 影片 三個項目
		#content-wp{
			dl#intro{
				margin:0 auto;			
				padding-top: 140px;
				dd {
					&#about{
						padding-top: 130px;		
						p{
							font-size: 21px;
							padding: 0 80px;
						}
					}
				}
			}
		}
		#inter {
			#banner{
				background:url(#{$img-path}/pad-banner.jpg) no-repeat left center;
				background-size: cover;
			}
		}
	}
}
@include susy-breakpoint(max-width 800px, $pad-susy){
	#ind-service{
		#item{
			.thin-line{
				top: 3px;
				right: 3px;
				bottom: 3px;
				left: 3px;
			}
			.wide-line{
				display: none;
			}
		}
	}
}
@import "pad/top-menu";